
export default function ProductDescriptionFields(props) {
  const data = props.data  

  return (
    <>
      {data.map(([key, value], index) => (
        <div key={index}> {/* Use index for key to avoid React key warning if keys are not unique */}
          <strong>{key}:</strong> {value}
        </div>
      ))}
    </>
  );
}