import {useEffect} from 'react';

export default function SearchBar() {
  useEffect(()=>{
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require('ix2').init();
    document.dispatchEvent(new Event('readystatechange'))
  })

const reqListener = function(event) {
  event.preventDefault(); // Prevent the default form submission behavior

  const searchString = document.getElementById("search").value;
  if (searchString.trim() !== "") {
    window.location.href = '/products/search/' + encodeURIComponent(searchString);
  }
};

  return (
    <>
    <form
    action="/search"
      data-w-id="3f12f538-8898-f11b-81e8-4804b663eebe"
       style={{
    WebkitTransform:
      "translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    MozTransform:
      "translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    msTransform:
      "translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    transform:
      "translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    opacity: 1
  }}
      onSubmit={reqListener}
      className="search hero-shop w-form"
    >
    <input
      type="search"
      className="search-input w-input"
      maxLength={256}
      name="query"
      placeholder="Search for Anything"
      id="search"
      required=""
    />
    <input
      type="submit"
      defaultValue="Search"
      className="search-button w-button"
    />
    </form>
    </>
  );
}
